import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import './App.css';

function App() {
	document.title = 'Trance.nu - It\'s time to say goodbye';
	return (
		<Grid container component="main" sx={{ height: '100vh' }}>
			<CssBaseline />
			<Grid
				item
				xs={false}
				sm={3}
				md={6}
				sx={{
					backgroundImage: 'url(https://images.unsplash.com/photo-1525362081669-2b476bb628c3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2574&q=80)',
					backgroundRepeat: 'no-repeat',
					backgroundSize: 'cover',
					backgroundPosition: 'center',
				}}
			/>


			<Grid item xs={12} sm={8} md={6} elevation={6} square>
				<Box
					sx={{
						my: 4,
						mx: 8,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<Typography variant="h2" component="h1" gutterBottom>
						Trance.nu - It's time to say goodbye
					</Typography>
					<Typography variant="h5" component="h2" gutterBottom>
						{"After 12 years, tens of millions of unique visitors, thousands of articles and million upon millions of topics it's time for trance.nu to finally be put to it's last rest. It's not an easy decision to take, but its something that has to be done to preserve the good memory that hopefully all of you have of trance.nu."}
					</Typography>
					<Typography variant="body1">The reasoning behind this is due to the lack of time to maintain the site and that the site need a complete restart to gain its former glory. A task that unfortunately is to big to undertake within a reasonable amount of time and effort. We all sincerely wish that we could revert time but unfortunately this is the position that we're at.<br /><br />Its been a fantastic ride which we're sure that no one will regret, we've released CDs, sailed the sevens seas on the trance.nu summer boat, we saw and helped djs rise, we've seen (and maybe helped) users fall in love and join each other in holy matrimony, we've spread trance to every corner of the world but more importantly we made friends, loads of good friend - friendships that will last for a lifetime.<br /><br />For that we are eternally grateful and would wish to thank every single one of you, keep in mind that you made the site the success it was, not us.<br /><br />We will apply a technique call sundown when shutting down the site which means that the forums have been put on read-only which the ability to log-in, sign guest books and send PMs will remain for another 2 weeks.<br /><br />This is to give a users a fair chance to hook up on alternative networks and/or sites to keep in touch. When the time is up we will disable the login-system and keep the site as an cemetery/archive for the time being.<br /><br />With that, this is the end - the final chapter, the last good bye and the chance for us to tell you all which fantastic time we've had together. Thank you for everything and hopefully we'll all stay in touch one way or another.<br /><br />Signed,<br />Tommie, Kevin and the trance.nu staff</Typography>
				</Box>
			</Grid>

		</Grid>
	);
}

export default App;
